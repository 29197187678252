
import QuotationPanelContext from "context/QuotationPanelContext"
import { useContext, useState, useEffect } from "react"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import Modal from "react-modal"
import { toast } from "react-toastify"
import CloseButton from "shared/CloseButton"
import NotificationModal from 'shared/modal/NotificationModal'
import { ContentCreatePanelList } from "./ModalCreatePanelList"
import iconPanelDelete from 'assets/icons/panel/botao-excluir.svg'
import iconPanelEdit from 'assets/icons/panel/botao-editar.svg'
import useWindowSize from "hooks/useWindowSize"
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from "const"

function ModalManagePanels({ isOpen = true, closeModal, createNewPanel }) {

  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH_MOBILE
  const { lists, selectListById, deleteList } = useContext(QuotationPanelContext)
  const [editingListId, setEditingListId] = useState(null)
  const [editableLists, setEditableLists] = useState(lists)
  const [isOpenNotification, setIsOpenNotification] = useState({ status: false })

  useEffect(() => {
    setEditableLists(lists.filter(({ type }) => type === 'QUOTATION_LIST'))
  }, [])

  const selectList = (listId) => {
    selectListById(listId)
    closeModal()
  }

  const removeList = async (list) => {
    try {
      await deleteList(list.id)
      closeModal()
      toast.success(`O painel ${list.name} foi deletada com sucesso!`)
    } catch (error) {
      toast.error('Não foi possível deletar o painel...')
    }
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={{
      content:
      {
        maxWidth: 648,
        margin: "auto",
        marginRight: isMobile ? -40 : "auto",
        marginLeft: isMobile ? -40 : "auto",
      }
    }}>
      <CloseButton onClick={closeModal} />
      {
        editingListId
          ? (
            <div className="h-full flex flex-col">
              <ContentCreatePanelList onSave={() => setEditingListId(null)} listIdToEdit={editingListId} />
              <button className="cursor-pointer text-gray-400 hover:text-gray-500 flex items-center my-2 font-semibold" onClick={() => setEditingListId(null)}>
                <MdKeyboardArrowLeft />
                Voltar para listagem
              </button>
            </div>
          ) : (
            <div className="relative flex flex-col h-full">
              <div className="mb-5">
                <h2 className="font-bold text-xl text-primary uppercase mb-2">Gerenciar Painéis</h2>
                <p className="font-semibold text-base text-primary">Renomeie ou exclua os seus painéis</p>
              </div>
              <div className="p-2">
                {
                  editableLists.map(list => (
                    <div className="flex text-xs border-b last:border-0 items-center space-x-2 py-2" key={list.id}>
                      <div className="flex-1 text-primary font-semibold space-x-2 flex items-center opacity-90 hover:opacity-100">
                        {list.name}
                      </div>
                      <div className="flex space-x-5">
                        <MdKeyboardArrowRight className="text-xl cursor-pointer text-primary" title="Visualizar painel" onClick={() => selectList(list.id)} />
                        <button className="text-primary text-xl opacity-90 hover:opacity-100 cursor-pointer" title="Editar painel" onClick={() => setEditingListId(list.id)}>
                          <img src={iconPanelEdit} alt="icone de editar" />
                        </button>
                        <button
                          onClick={() => setIsOpenNotification({ status: true, list })}
                          className={`text-loss text-xl opacity-90 cursor-pointer hover:opacity-100`}
                          title="Remover painel"
                        >
                          <img src={iconPanelDelete} alt="icone de excluir" />
                        </button>
                      </div>
                    </div>
                  ))
                }
              </div>
              <div>
                <button className="btn btn-primary m-2 text-secondary" onClick={createNewPanel}>Criar um novo painel</button>
              </div>
            </div>
          )
      }
      <NotificationModal
        isOpen={isOpenNotification.status}
        closeModal={() => setIsOpenNotification({ status: false })}
        title={`Confirma a exclusão do painel ${isOpenNotification?.list?.name}?`}
        style={{ content: { maxWidth: 460, maxHeight: width < 500 ? 360 : 260, borderRadius: 10, margin: "auto" } }}
        successModal={() => removeList(isOpenNotification.list)}
        actionButtons />
    </Modal >
  )

}

export default ModalManagePanels