import moment from "moment"
import QuoteApi from "api/QuoteApi"
import SocketBroker from 'api/SocketBroker'
import { dateFormatter } from "./helpers"
import { Keys } from "const";

let history,
  resolutionActive = false,
  symbolActive,
  loadedHistoricalSeries = false;

const ORIGINS = {
  'VONEFRE': {
    1: "streaming",
    2: "delayed_streaming",
    687: "delayed_streaming",
    3083: "streaming"
  },
  'VONEBAS': {
    1: "streaming",
    2: "streaming",
    687: "delayed_streaming",
    3083: "streaming"
  },
  'VONEINT': {
    1: "streaming",
    2: "streaming",
    3: "delayed_streaming",
    229: "delayed_streaming",
    343: "delayed_streaming",
    687: "delayed_streaming",
    3083: "streaming",
    15158: "delayed_streaming",
    15160: "delayed_streaming",
    17195: "delayed_streaming",
    57201: "delayed_streaming"
    
  },
  'VONEAVA': {
    1: "streaming",
    2: "streaming",
    3: "streaming",
    229: "delayed_streaming",
    343: "delayed_streaming",
    345: "delayed_streaming",
    660: "delayed_streaming",
    687: "streaming",
    691: "delayed_streaming",
    970: "delayed_streaming",
    3083: "streaming",
    15153: "delayed_streaming",
    15157: "delayed_streaming",
    15158: "delayed_streaming",
    15159: "delayed_streaming",
    15160: "delayed_streaming",
    15163: "delayed_streaming",
    17195: "delayed_streaming",
    57201: "delayed_streaming",
    59230: "delayed_streaming"
  }
};

const configurationData = {
  supported_resolutions: ["1", "5", "15", "30", "45", "60", "D", "W", "M", "3M", "6M"],
  exchanges: [
    {
      value: "",
      name: "Exchange",
      desc: "Exchange not used",
    },
  ],
  // symbols_types: [
  //   {
  //     name: "Bovespa",
  //     value: "Bovespa",
  //   },
  // ],
};

const intraday = ["1", "5", "15", "30", "45", "60"]
const day = ["1D"]
const week = ["1W"]
const month = ["1M", "3M", "6M"]

// function updateWS({ name, origin }) {
//   if (name && origin) SocketBroker.subscribe({symbol: name, origin}, (data) => {
//     if (data['VO002']) {
//       symbolWS = data
//     } 
//   })
// }

async function getAllSymbols(userInput) {

  const indexInput = userInput.indexOf(':')
  let formaterInput
  indexInput !== -1 ? formaterInput = userInput.slice(indexInput+1) : formaterInput = userInput 
  
  const data = await QuoteApi.autocompleteSuggestion(formaterInput)
  if (data && data.length) {
    const symbols = await data.map((symbol) => {
      const newSymbol = {
        symbol: symbol.symbolCode,
        full_name: `${symbol.origin}:${symbol.symbolCode}`,
        description: symbol.securityDesc,
        exchange: configurationData.exchanges[0].value,
        type: symbol.origin,
        originId: symbol.originId
      }
      return newSymbol 
    });
    return [...symbols] 
  }
  SocketBroker.unsubscribeWS({symbol: symbolActive.name}) 
}

async function actionDataPersistence(setDataPersistence) {
  console.log('[dataPersistence]: onAutoSaveNeeded')
  setDataPersistence && setDataPersistence({ symbol: window.tvWidget.activeChart().symbolExt().description })
  
  const getAllStudies = window.tvWidget.activeChart().getAllStudies()
  const setStudies = new Set()

  if(getAllStudies) {
    const filterStudies = getAllStudies.filter((study) => {
     const duplicated = setStudies.has(study.name)
     setStudies.add(study.name)
     return !duplicated
   })

   if (filterStudies) {
     window.localStorage.setItem('dataStudies', JSON.stringify(filterStudies))
   }
  }

  // const getAllShapes = window.tvWidget.activeChart().getAllShapes()
  // const setShapes = new Set()

  // if(getAllShapes) {
  //   const listShapes = getAllShapes.map((shape) => {
  //     const properties = window.tvWidget.activeChart().getShapeById(shape.id).getProperties()
  //     const position = window.tvWidget.activeChart().getShapeById(shape.id).getPoints()
  //     return { shape, properties, position }
  //   })
    
  //   const dataShapes = JSON.parse(window.localStorage.getItem('dataShapes'))

  //   const allShapes = dataShapes ? [...dataShapes, ...listShapes] : [...listShapes]
    
  //   const filterShapes = allShapes.filter(({ shape, position }) => {
  //     const duplicated = setShapes.has(shape.id)
  //     setShapes.add(shape.id)
  //     return !duplicated && position.length
  //   })

  //   window.localStorage.setItem('dataShapes', JSON.stringify(filterShapes))
  // }
  
}

function setRange(data) {
  if (data === '60') {
    const dateNow = moment().unix()
    const dateOld = moment().subtract(1,'months').unix()
    window.tvWidget.activeChart().setVisibleRange({ from: dateOld, to: dateNow }).then(() => console.log(`Range month: ${data}`));
    // window.tvWidget.setTimeFrame({
    //   val: { type: 'period-back', value: '2M' },
    //   res: '60',
    // });
  }
  if (data === '1D') {
    const dateNow = moment().unix()
    const dateOld = moment().startOf('year').unix()
    window.tvWidget.activeChart().setVisibleRange({ from: dateOld, to: dateNow }).then(() => console.log(`Range year: ${data}`));
  }
}

const Datafeed = () => ({

  history: history,

  onReady: (callback) => {
    console.log("[onReady]: Method call");
    setTimeout(() => callback(configurationData));
    //window.tvWidget.setTimeFrame({val: {type: 'period-back', value: '1D'}, res: '1'});
  },

  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {
    console.log("[searchSymbols]: Method call");
    const symbols = await getAllSymbols(userInput);
    if (symbols) {
      const newSymbols = symbols.filter((symbol) => {
        const isExchangeValid = exchange === "" || symbol.exchange === exchange;
        const isFullSymbolContainsInput =
        symbol.symbol.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
        let isDescriptionContainsInput
        if (symbol.description) isDescriptionContainsInput = symbol.description.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
        return isExchangeValid && (isFullSymbolContainsInput || isDescriptionContainsInput);
      });
      onResultReadyCallback(newSymbols);
    }
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    console.log("[resolveSymbol]: Method call", symbolName);

    const storageStudies = window.localStorage.getItem('dataStudies')
    const dataStudies = storageStudies ? JSON.parse(storageStudies) : null
    
    if (dataStudies && dataStudies.length > 0) {
      dataStudies.forEach(async (study) => {
        window.tvWidget.activeChart().removeAllStudies()
        if(study.name === 'Overlay') {
          window.tvWidget.activeChart().removeEntity(study.id)
          return
        }
        await window.tvWidget.activeChart().createStudy(study.name, false, false) 
      })  
    }
    
    window.tvWidget.activeChart().setChartType(JSON.parse(window.localStorage.getItem('tradingview.chart.lastUsedStyle')) || 3)
    // const dataShapes = JSON.parse(window.localStorage.getItem('dataShapes'))
    // if (dataShapes && dataShapes.length > 0) {
    //   dataShapes.forEach(async (data) => {
    //     if(data.position.length === 1) {
    //       await window.tvWidget.activeChart().createShape({ time: data.position[0].time }, { shape: data.shape.name })
    //     } else {
    //       await window.tvWidget.activeChart().createMultipointShape(data.position, { shape: data.shape.name, ...data.properties })
    //     }
    //   })
    // }

    const symbols = await getAllSymbols(symbolName);
    const symbolItem = symbols.find(
      ({ full_name }) => full_name === symbolName
    );
    if (!symbolItem) {
      console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
      onResolveErrorCallback("cannot resolve symbol");
      return;
    }
    
    console.log("[resolveSymbol]: Symbol resolved", symbolName);
    const symbolInfo = {
      originId: symbolItem.originId,
      ticker: symbolItem.full_name,
      name: symbolItem.symbol,
      description: symbolItem.full_name,
      type: symbolItem.type,
      session: "24x7",
      timezone: "Etc/UTC",
      exchange: symbolItem.exchange,
      minmov: 1,
      pricescale: 100,
      visible_plots_set: true,
      has_intraday: true,
      //intraday_multipliers: ["1", "5", "15", "45", "60"],
      has_daily: true,
      has_weekly_and_monthly: true,
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 2,
      data_status: ORIGINS[window.planActive][symbolItem.originId],
      delay: ORIGINS[window.planActive][symbolItem.originId] === 'delayed_streaming' ? (15 * 60) : false  
    }
    onSymbolResolvedCallback(symbolInfo)
  },

  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {

    if (['60', '1D'].includes(resolution) && resolutionActive !== resolution) {
      resolutionActive = resolution
      setRange(resolution)
    }

    const [{fieldsMap}] = await QuoteApi.marketIndicators(symbolInfo.originId, symbolInfo.name, [Keys.GMT])
    const gmtSymbol = fieldsMap[0].value
    
    symbolActive = symbolInfo
    const { from, to, firstDataRequest, countBack } = periodParams;
    console.log("[getBars]: Method call", symbolInfo, resolution, from, to, countBack, firstDataRequest);
    if (firstDataRequest) history = { bar: { time: new Date().getTime() }, last: { time: new Date().getTime() }, resolution }
    else history = { bar: history.resolution !== resolution ? null : history.bar, last: { time: new Date().getTime() }, resolution }
    let data
    const delay = ORIGINS[window.planActive][symbolInfo.originId] === 'delayed_streaming' ? 15 : false
    const { dateFrom , dateTo } = dateFormatter(resolution, firstDataRequest, gmtSymbol, delay, !history ? new Date().getTime() : history.bar.time)
    loadedHistoricalSeries = true
    if(ORIGINS[window.planActive][symbolInfo.originId]) {
      if (intraday.includes(resolution)) data = await QuoteApi.seriesMinute(symbolInfo, dateFrom, dateTo)
      else if (day.includes(resolution)) data = await QuoteApi.seriesDaily(symbolInfo, dateFrom, dateTo)
      else if (week.includes(resolution)) data = await QuoteApi.seriesWeek(symbolInfo, dateFrom, dateTo)
      else if (month.includes(resolution)) data = await QuoteApi.seriesMonth(symbolInfo, dateFrom, dateTo)
      else data = []
    } else data = []
    
    setTimeout(() => {
      try {
        if (data.length === 0) {
          onHistoryCallback([], {
            noData: true,
          });
          return;
        }
        
        let bars = [] 
         data.forEach((bar) => {
          bars = [
            ...bars,
            {
              time: moment(bar.timepoint).unix() * 1000,
              low: bar.sessionLow,
              high: bar.sessionHigh,
              open: bar.sessionOpen,
              close: bar.sessionLast,
              volume: bar.volume
            }
          ]
        });
        const barsTimestampSort = bars.sort((x,y) => x.time - y.time)
        history = { bar: { time: new Date(dateFrom).getTime() }, last: barsTimestampSort[barsTimestampSort.length-1],  resolution }
        if (intraday.includes(resolution)) onHistoryCallback(barsTimestampSort, { noData: dateFrom ? false : true })
        if (day.includes(resolution) && dateFrom !== dateTo) onHistoryCallback(barsTimestampSort, { noData: dateFrom ? false : true })
        if (week.includes(resolution) && dateFrom !== dateTo) onHistoryCallback(barsTimestampSort, { noData: dateFrom ? false : true })
        if (month.includes(resolution) && dateFrom !== dateTo) onHistoryCallback(barsTimestampSort, { noData: dateFrom ? false : true })
        console.log(`[getBars]: returned ${barsTimestampSort.length} bar(s)`);
        return;
      } catch (error) {
        console.log("[getBars]: Get error", error);
        onErrorCallback(error);
      }
    })  
  },

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback,
  ) => {
    if (loadedHistoricalSeries && ORIGINS[window.planActive][symbolInfo.originId]) {
      SocketBroker.subscribe({symbol: symbolInfo.name, origin: symbolInfo.originId }, (data) => {
        const time = Date.parse(data[Keys.HULTGMT]) + (data[Keys.GMT] * 60000)
        if (time) {
          if ([...day, ...week, ...month].includes(history.resolution)) {
            onRealtimeCallback({
              time,
              low: data[Keys.MIN],
              high: data[Keys.MAX],
              open: data[Keys.ABER],
              close: data[Keys.ULTCOT],
              volume: data[Keys.QTT] 
            })
          } else {
            onRealtimeCallback({
              time,
              low: data[Keys.ULTCOT],
              high: data[Keys.ULTCOT],
              open: data[Keys.ULTCOT],
              close: data[Keys.ULTCOT],
              volume: data[Keys.QTT] 
            })
          }
        }
      })
      loadedHistoricalSeries = false
    }
    
    console.log(
      "[subscribeBars]: Method call with subscribeUID:",
      subscribeUID, symbolInfo
    );
  },

  unsubscribeBars: (subscribeUID) => {
    console.log(
      "[unsubscribeBars]: Method call with subscribeUID:",
      subscribeUID
    );
    // const start = subscribeUID.indexOf(':') + 1
    // const end = subscribeUID.indexOf('_')
    // const unsubscribeSymbol = subscribeUID.slice(start, end)
    // if (symbolActive.name !== unsubscribeSymbol) {
      
    // }
  },
});

export { Datafeed, actionDataPersistence }
