import { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import CompaniesApi from "api/CompaniesApi"
import NewsApi from "api/NewsApi"
import Price from "shared/websocketData/Price"
import { ICONS_MAP, LAYOUT_BREAKPOINT_WIDTH, ORIGINS } from "const"
import { dateStringToLocaleString } from "helpers/dateHelper"
import Modal from "react-modal"
import { MdClose } from "react-icons/md"
import { usePermission } from "hooks/usePermission"
import useTitle from "hooks/useTitle"
import useWindowSize from "hooks/useWindowSize"
import Loading from "shared/Loading"
import { toast } from "react-toastify"
import { deburr, lowerCase } from "lodash"

function SearchResult() {

  const [searchParams] = useSearchParams()
  const [companiesList, setCompaniesList] = useState(null)
  const [selectedNews, setSelectedNews] = useState(null)
  const [newsList, setNewsList] = useState(null)
  const [isLoading, setIsLoading] = useState()
  const permission = usePermission('News')

  const searchCompanyName = searchParams.get('empresa')
  const isCompany = !!searchCompanyName
  let searchText = isCompany ? searchCompanyName : searchParams.get('texto')

  useTitle(`Busca por "${searchText}"`)

  useEffect(() => {
    CompaniesApi.search(searchText).then(list => {
      if (isCompany) {
        const exactlyCompany = list.filter(company => deburr(lowerCase(company.name)) === deburr(lowerCase(searchText)))
        setCompaniesList(exactlyCompany)
        return
      }
      setCompaniesList(list)
    })
  }, [searchText, isCompany])

  const origin = useMemo(() => {
    if (!permission) return null
    return ORIGINS.filter(origin =>
      permission?.childrens?.some((permission) => permission.key === origin.key)
    ).map(x => x.key)
  }, [permission])

  useEffect(() => {
    if (!origin) return

    const fetchNews = async () => {
      setIsLoading(true)
      try {
        const response = await NewsApi.fetchNewsList({
          origin,
          keyword: !isCompany ? [searchText] : [],
          companies: isCompany ? [searchText] : [],
          perPage: 10
        })

        if (!response) {
          setNewsList([])
          throw new Error(`Erro: ${response.status}`)
        }

        setNewsList(response)
      } catch (error) {
        console.error(error)
        toast.error('Falha ao buscar notícias!')
      }
      setIsLoading(false)
    }

    fetchNews()
  }, [searchText, setCompaniesList, origin, isCompany])

  return (
    <section className="p-3 min-h-full">
      <div className="bg-white h-full rounded flex flex-col py-3">
        <h1 className="text-xl text-primary font-bold uppercase p-3">Mostrando resultados para "{searchText}"</h1>
        {companiesList?.length ? <h2 className="text-lg text-primary font-bold p-3">Empresas</h2> : ''}
        {companiesList?.length ? companiesList?.map(props => <CompanyDetails {...props} />) : ''}

        <h2 className="text-lg text-primary font-bold p-3">Notícias</h2>
        {(!newsList?.length && isLoading) ?
          <Loading className="ml-3" size="30" /> :
          newsList?.map(news =>
            <div key={news.link} className="cursor-pointer hover:bg-[#F6F6F6] flex space-x-3 p-3 items-center" onClick={() => setSelectedNews(news)}>
              <img src={ICONS_MAP.get(news.origin)} className={`rounded-full w-5 h-5`} alt={news.origin} />
              <div className="space-y-1">
                <div className="text-sm font-semibold leading-tight">
                  {news.title}
                </div>
                <p className="text-xs text-gray-500">
                  {dateStringToLocaleString(news.publicationDate)}
                </p>
              </div>
            </div>
          )}

      </div>
      <ModalShowNews selectedNews={selectedNews} closeModal={() => setSelectedNews(null)} />

    </section>

  )
}

function CompanyDetails({ name, title, symbols }) {
  return (
    <div className="pb-2 mb-2 px-3 cursor-pointer hover:bg-[#F6F6F6]">
      <div className="flex space-x-1 items-baseline mb-2">
        <h2 className="text-sm font-semibold">{name}</h2>
        <h3 className="font-semibold opacity-50 text-xs">{title}</h3>
      </div>
      <div className="flex space-x-2">
        {
          symbols.map(symbol => (
            <div className="flex space-x-1 text-xs" key={'sbl_' + symbol}>
              <h3 className="font-semibold">{symbol}</h3>
              <Price symbol={symbol} origin={2} containerClass="flex space-x-1 items-baseline" varClass="text-xs" />
            </div>
          ))
        }
      </div>
    </div>
  )
}

function ModalShowNews({ selectedNews, closeModal = () => { } }) {

  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH

  return (
    <Modal
      isOpen={selectedNews}
      onRequestClose={closeModal}
      style={{ content: { padding: 0, maxWidth: 648, margin: "auto", borderRadius: '12px', inset: isMobile ? '5px' : '40px' } }}
    >
      <div className="relative h-full overflow-hidden">
        <button className="sticky float-right right-2 top-2 text-xl text-primary" title="Fechar" onClick={closeModal}>
          <MdClose />
        </button>
        {selectedNews?.link ? (
          <iframe src={selectedNews.link} className="w-full h-[97%] px-8 mini-scrollbar" title={selectedNews.title} />
        ) : (
          <div className="text-grey-400 text-sm p-2">Unavailable</div>
        )}
      </div>
    </Modal>
  )
}

export default SearchResult