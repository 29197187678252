import { useEffect, useState } from "react"

function useWindowSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

export function useDynamicWindowSize() {
  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight })
  useEffect(() => {
    const handleResize = () => setSize({ width: window.innerWidth, height: window.innerHeight })
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [setSize])

  return size
}

export default useWindowSize