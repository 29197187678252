import { useContext, useState, Fragment, useEffect, createRef } from "react"
import { FundsContext } from 'context/FundsContext'
import IconListUser from 'assets/icons/panel/user_lists.svg'
import IconLists from 'assets/icons/panel/lists.svg'
import {
  MdOutlineArrowDropDown,
  MdOutlineArrowDropUp,
  MdAdd,
  MdChevronLeft,
  MdChevronRight,
} from 'react-icons/md'
import { ModalCreateFundsList } from "shared/modal/Funds/ModalCreateFundsList"
import useClickout from "hooks/useClickout"


export function DropDown({ listPanel, reducedVersion = false, openModal = false, fullSize = false, automaticClose = false, ...props }) {
  const { selectedCategory, setSelectedCategory, updatedListFunds, formattedListPanel, formattedListCategory, categoriesGroup } = useContext(FundsContext)
  const [subCategories, setSubCategories] = useState([])
  const [isOpen, setIsOpen] = useState(openModal)
  const [isOpenCategory, setIsOpenCategory] = useState(false)
  const [createListModalIsOpen, setCreateListModalIsOpen] = useState(false)

  const dropdownRef = createRef()
  const dropdownTriggerRef = createRef()
  const dropdownCategoryRef = createRef()
  
  useClickout([dropdownTriggerRef, dropdownRef], clickout => {
    if (clickout) setIsOpen(false)
  })

  useClickout([dropdownCategoryRef], clickout => {
    if (clickout) setIsOpenCategory(false)
  })

  const menus = [
    { order: 1, title: '' },
    { order: 2, title: 'Categoria:' }
  ]

  function selectCategoryGroup(list) {
    setTimeout(() => {
      if (list.type === 'simple') {
        list.id = 1
        setSelectedCategory(list)
        updatedListFunds(list)
      }
      if (list.type === 'level') {
        setIsOpenCategory(list)
      }
      setIsOpen(false)
    }, 0)
  }

  function selectPanelList(list) {
    formattedListPanel(list)
    setSelectedCategory(list)
    setIsOpenCategory(false)
    setIsOpen(false)
  }

  function updatedListTable(cat) {
    setSelectedCategory(cat)
    updatedListFunds(cat)
    setIsOpenCategory(false)
  }

  function toggleMenuLevel() {
    setTimeout(() => {
      setIsOpen(true)
      setIsOpenCategory(false)
    }, 0)
  }

  function createList() {
    setCreateListModalIsOpen(true)
    setIsOpenCategory(false)
    setIsOpen(false)
  }

  function selectedListCategoryAll() {
    setSelectedCategory(isOpenCategory)
    formattedListCategory(isOpenCategory, isOpenCategory.subCatId.map(({ id }) => id))
    setIsOpenCategory(false)
  }

  useEffect(() => {
    if (isOpenCategory.type === 'level') {
      const subCatId = isOpenCategory.subCatId
      setSubCategories(subCatId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenCategory])

  useEffect(() => {
    if (automaticClose) setIsOpen(false)
  }, [automaticClose])

  return (
    selectedCategory && <div className={`relative`}>
      {!reducedVersion && <button className="flex items-center space-x-2" ref={dropdownTriggerRef} onClick={() => setIsOpen((e) => {
        if (!e) setIsOpenCategory(false)
        return !e
      })}>
        <div className="flex justify-center items-center text-primary">
          <img src={selectedCategory['name'] ? IconLists : IconListUser} alt="Ícone de painéis" />
        </div>
        <span className="w-40 text-primary text-left font-bold text-base truncate uppercase">{selectedCategory?.id === 1 ? 'OUTROS' : selectedCategory?.name || selectedCategory.panelName}</span>
        <span className={`w-10 h-10 flex justify-center items-center bg-[#EDEDED] hover:bg-[#DBDBDB] rounded-lg pointer-events-none ${(isOpen || isOpenCategory) && 'border-2 border-gray-300'}`}>
          {(isOpen || isOpenCategory) ? <MdOutlineArrowDropUp className="text-primary text-2xl" /> : <MdOutlineArrowDropDown className="text-primary text-2xl" />}
        </span>
      </button>}
      {isOpen && (
        <div ref={dropdownRef} style={{ zIndex: 500 }} className={`absolute ${props.size === 1 ? 'top-12 left-0 right-0' : 'min-[300px]'} max-h-[420px] min-w-[300px] ${fullSize && 'w-full'} mini-scrollbar overflow-y-auto overflow-x-hidden fixed bg-white rounded-xl border border-gray-200 drop-shadow-md`}>
          {menus.map((group, i) => {
            return (
              <Fragment key={`menus_dropdown_${group.order}`}>
                {group.title && <div className="ml-4 mt-1 text-base text-dark-gray font-semibold">{group.title}</div>}
                <div className="w-full">
                  {{
                    1: <div>
                      {listPanel && listPanel.map((item) => {
                        return (
                          <div key={`dropdown_panel_list_${item.id}`} onClick={() => selectPanelList(item)} className={`w-full flex m-1 space-x-2 py-2 px-4 ${item.id === selectedCategory.id && selectedCategory['panelName'] && 'bg-[#EDEDED]'} hover:bg-[#EDEDED] hover:cursor-pointer rounded-xl`}>
                            <div className="flex justify-center items-center text-primary">
                              <img src={IconListUser} alt="Ícone de painéis" />
                            </div>
                            <span className="w-full text-primary font-semibold text-ellipsis overflow-hidden uppercase">{item.panelName}</span>
                          </div>
                        )
                      }).sort((a, b) => a.panelOrder - b.panelOrder)}
                      <div className="w-full border-b border-gray-200"></div>
                      {!reducedVersion && <div
                        onClick={() => createList()}
                        className={`flex items-center m-1 space-x-2 py-2 px-4 text-primary cursor-pointer `}
                      >
                        <span className="bg-primary text-secondary rounded-full">
                          <MdAdd style={{ width: '16px', height: '16px' }} />
                        </span>
                        <span className="text-primary font-semibold uppercase">Criar nova lista</span>
                        {/* {!permission?.childrens?.some(({ key }) => key === "CreatePanel") && <MdOutlineDiamond className="text-base text-terciary ml-2 inline" />} */}
                      </div>}
                    </div>, 
                    2: categoriesGroup.map((item) => {
                      return (
                        <div key={`dropdown_categories_list_${item.name}`}
                          onClick={() => selectCategoryGroup(item)}
                          className={`${((item.subCatId.some(({id}) => id === selectedCategory.id) && selectedCategory['name']) || (item.type === 'simple' && selectedCategory.type === 'simple')) && 'bg-[#EDEDED]'} flex m-1 space-x-2 py-2 px-4 hover:bg-[#EDEDED] hover:cursor-pointer rounded-xl`}
                        >
                          <div className="flex justify-center items-center text-primary">
                            <img src={IconLists} alt="Ícone de painéis" />
                          </div>
                          <div className="flex items-center">
                            <span className="w-full text-primary font-semibold text-ellipsis overflow-hidden uppercase">{item.name}</span>
                            {item.type === 'level' && <MdChevronRight className="text-primary text-xl" />}
                          </div>
                        </div>
                      )
                    })
                  }[group.order]}
                  {listPanel && listPanel.length - 1 !== i && <div className="w-full border-b border-gray-200"></div>}
                </div>
              </Fragment>
            )
          })}
        </div>
      )}
      {isOpenCategory && (
        <div ref={dropdownCategoryRef} style={{ zIndex: 1000 }} className={`absolute ${props.size === 1 ? 'top-12 left-0 right-0' : 'min-[300px]'} z-50 max-h-[440px] min-w-[300px] ${fullSize && 'w-full'} mini-scrollbar overflow-auto fixed bg-white rounded-xl border border-gray-200 drop-shadow-md`}>
          <div onClick={() => toggleMenuLevel()} className={`flex items-center m-1 space-x-2 py-2 px-4 bg-[#EDEDED] cursor-pointer rounded-xl`}>
            <MdChevronLeft className="text-xl text-primary" />
            <div className="flex justify-center items-center text-primary">
              <img src={IconLists} alt="Ícone de painéis" />
            </div>
            <span className="w-full text-primary font-semibold uppercase text-ellipsis overflow-hidden">{isOpenCategory.name}</span>
          </div>
          <div onClick={() => selectedListCategoryAll()} className={`flex m-1 space-x-2 py-2 px-4 hover:bg-[#EDEDED] hover:cursor-pointer rounded-xl`}>
            <span className="w-full text-primary font-semibold text-ellipsis overflow-hidden uppercase">Ver todos</span>
          </div>
          <div className="w-full border-b border-gray-200"></div>
          {subCategories.map((cat) => {
            return (
              <div onClick={() => updatedListTable(cat)} className={`${selectedCategory.id === cat.id && 'bg-[#EDEDED]'} flex m-1 space-x-2 py-2 px-4 hover:bg-[#EDEDED] hover:cursor-pointer rounded-xl`} key={`dropdown_item_list_${cat.name}`}>
                <span className="w-full text-primary font-semibold text-ellipsis overflow-hidden uppercase">{cat.name}</span>
              </div>
            )
          })}
        </div>
      )}
      {createListModalIsOpen && (<ModalCreateFundsList closeModal={() => setCreateListModalIsOpen(false)} />)}
    </div>
  )
}