import { useContext, useEffect, useState } from "react"
import { Tooltip } from "react-tooltip"
import { MdAdd, MdRemove } from "react-icons/md"
import PreferencesContext from "context/PreferencesContext"
import { useCustomId } from "hooks/useCustomId"

export function BtnToggleMyList({ symbol, origin, className }) {
  const [inMyList, setInMyList] = useState(false)
  const id = useCustomId(`toggle_my-list`)
  const { interestList, toggleMyList, isSymbolInInterestList } = useContext(PreferencesContext)

  useEffect(() => {
    setInMyList(isSymbolInInterestList(symbol, origin))
  }, [interestList, symbol, origin, isSymbolInInterestList])

  return symbol && origin && (
    <button
      className={`w-4 h-4 flex justify-center items-center rounded-full font-semibold text-base ${className} ${inMyList ? 'bg-secondary text-primary' : 'bg-primary text-secondary'}`}
      id={id}
      type="button"
      onClick={() => toggleMyList(symbol, origin)}>
      {inMyList ? <MdRemove /> : <MdAdd />}
      {id && (
        <Tooltip anchorSelect={'#' + id} className="text-center bg-primary p-1 font-semibold text-xs">
          {inMyList ? `Excluir dos` : `Adicionar aos`} favoritos do Painel
        </Tooltip>
      )}
    </button>
  )
}