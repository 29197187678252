import { nonNumericRegExp } from "const"
import AlertsContext from "context/AlertsContext"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import Modal from "react-modal"
import { toast } from "react-toastify"
import CloseButton from "shared/CloseButton"
import ControlledInput from "shared/ControlledInput"
import AlertAmountInput from "./AlertAmountInput"
import SymbolSelect from "shared/SymbolSelect"
import NotificationModal from 'shared/modal/NotificationModal'
import { Keys } from 'const'
import { errorCodeHandler } from "helpers/errorCodeHandler"

const criteriaTypes = { PERCENT: 0, CURRENCY: 1, NUMBER: 2 }

const criteriaKeys = {
  price: 'ULTCOT',
  var_percent: 'VAR',
  var_pts: 'DIFFEC',
  max: 'MAX',
  min: 'MIN',
  vol: 'VOLF',
  num_neg: 'NEG'
}

function ModalAlertQuoteForm({ isOpen = true, closeModal, editItem }) {
  const { newQuotationAlert, updateQuotationAlert } = useContext(AlertsContext)
  const methods = useForm()
  const { criterion, symbol, amount, name, operator } = methods.watch()
  const isFormValid = criterion && symbol && name?.length > 1 && !isNaN(amount) && operator
  const [isOpenNotification, setIsOpenNotification] = useState({ status: false })

  const operatorOptions = useMemo(() => [
    { value: '=', label: 'Igual a' },
    { value: '<>', label: 'Diferente de' },
    { value: '>', label: 'Maior que' },
    { value: '<', label: 'Menor que' },
    { value: '>=', label: 'Maior ou igual a' },
    { value: '<=', label: 'Menor ou igual a' },
  ], [])

  const criteriaOptions = useMemo(() => [
    { value: 'price', label: 'Cotação', type: criteriaTypes.CURRENCY },
    { value: 'var_percent', label: 'Variação no dia em %', type: criteriaTypes.PERCENT },
    { value: 'var_pts', label: 'Variação no dia em pontos', type: criteriaTypes.NUMBER },
    { value: 'max', label: 'Máxima', type: criteriaTypes.CURRENCY },
    { value: 'min', label: 'Mínima', type: criteriaTypes.CURRENCY },
    { value: 'vol', label: 'Volume financeiro', type: criteriaTypes.CURRENCY },
    { value: 'num_neg', label: 'Número de negócios', type: criteriaTypes.NUMBER },
  ], [])
  const decimalInputs = [criteriaTypes.CURRENCY, criteriaTypes.PERCENT]

  useEffect(() => {
    if (editItem) {
      methods.reset({
        id: editItem.id,
        operator: editItem.operator,
        criterion: editItem.criterion,
        amount: editItem.price,
        name: editItem.name,
        symbol: {
          symbolCode: editItem.symbol,
          originId: editItem.origin
        },
      })
    }
  }, [editItem, methods])

  const onSubmit = useCallback(async (data) => {
    const params = {
      name: data.name,
      symbol: data.symbol?.symbolCode,
      origin: data.symbol?.originId,
      criterion: data.criterion,
      operator: data.operator,
      price: typeof data.amount === 'string' ? (String(data.amount).replace(nonNumericRegExp, '') / 100) : data.amount
    }

    if (Object.values(params).some((v) => typeof v !== "number" && !v)) {
      toast.error('Preencha todos os campos do formulário!')
      return
    }

    try {
      if (data.id) {
        await updateQuotationAlert({ id: data.id, ...params })
        toast.success('Alerta alterado com sucesso!')

      } else {
        await newQuotationAlert(params)
        toast.success('Alerta criado com sucesso!')
      }
      methods.reset()
      closeModal()
    } catch (error) {
      setIsOpenNotification({ status: true, msg: errorCodeHandler(error) })
    }

  }, [closeModal, newQuotationAlert, updateQuotationAlert])


  if (!methods || (editItem && !symbol)) return null

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={{ content: { maxWidth: 648, margin: "auto" } }} shouldCloseOnEsc={true}>
      <CloseButton onClick={closeModal} />
      <h2 className="font-bold text-xl text-primary uppercase mb-2">Alerta de Cotação</h2>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col">
          <ControlledInput name="name" label="Defina um nome para o alerta*" placeholder="Ex.: Oportunidade de compra" />
          <SymbolSelect label="Escolha o ativo*" name="symbol" />
          <ControlledInput label="Selecione o critério*">
            <div className="select-wrapper">
              <select {...methods.register('criterion')} id="criterion" className="input" defaultValue={'price'}>
                {criteriaOptions.map(({ value, label }) => <option key={'crit_' + value} value={value} >{label}</option>)}
              </select>
            </div>
          </ControlledInput>
          <ControlledInput label="Selecione a condição*">
            <div className="select-wrapper">
              <select {...methods.register('operator')} id="operator" className="input" defaultValue={'>='}>
                {operatorOptions.map(({ value, label }, idx) => <option key={'cond_' + idx} value={value} >{label}</option>)}
              </select>
            </div>
          </ControlledInput>
          <AlertAmountInput
            symbol={symbol}
            assetTypeKey={Keys[criteriaKeys[criterion]]}
            name="amount"
            label="Defina o valor*"
            type="number"
            decimal={!!decimalInputs.includes(criterion)}
          />

          <span className="mt-2 text-sm">*Campos de preenchimento obrigatório.</span>
          <button className="btn btn-primary mt-4" type="submit" disabled={!isFormValid}>Salvar</button>
        </form>
      </FormProvider>
      <NotificationModal
        isOpen={isOpenNotification.status}
        closeModal={() => setIsOpenNotification({ status: false })}
        title={isOpenNotification?.msg}
        style={{ content: { maxWidth: 400, maxHeight: 272, borderRadius: 10, margin: "auto" } }}
        actionButtons='one'
        closeLabel='fechar'
      />
    </Modal>

  )
}

export default ModalAlertQuoteForm