import { BASE_URL, getApiHeaders, customFetch } from "./settings"
import { FetchError, InternalFetchError } from "errors/FetchError"

const URL = "PanelInvest"

const Api = {
  getListId: async function ({ id }) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL}/${id}`, { method: 'GET',
        headers: getApiHeaders(),
      })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
        return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  updateLists: async function (body) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL}`, { 
        method: 'PUT',
        headers: getApiHeaders(),
        body: JSON.stringify(body)
      }) 
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  }
}

export default Api