import { useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md"
import QuoteApi from "api/QuoteApi"
import { Keys } from "const"
import { Loading } from "shared/Loading"

function AlertAmountInput({ label, name, mask, decimal, errors, symbol, assetTypeKey, options = {}, ...props }) {
 
  const { register, setValue, getValues } = useFormContext()

  const [decimalPlaces, setDecimalPlaces] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  let preventUpdateValue = useRef(!!symbol && !isNaN(getValues("amount")), [symbol, getValues])

  useEffect(() => {
    const updateAmount = (val) => {
      if (preventUpdateValue.current) {
        preventUpdateValue.current = false
        return
      }
      setValue("amount", val)
    }

    if (symbol && assetTypeKey) {
      setIsLoading(true)
      QuoteApi.marketIndicators(symbol.originId, symbol.symbolCode, `${assetTypeKey},${Keys.CDEC}`).then(([{ fieldsMap }]) => {
        if (fieldsMap[0]) {
          setDecimalPlaces(fieldsMap[1].value)
          updateAmount(Number(fieldsMap[0].value).toFixed(fieldsMap[1].value).toLocaleString("pt-BR"))
        }
      }).finally(() => setIsLoading(false))
    }
  }, [symbol, assetTypeKey, setDecimalPlaces, setIsLoading, setValue])

  options.valueAsNumber = true
  return (
    <div className="relative">
      <div className="flex space-x-1">
        {label ? <label htmlFor={name} className="label">{label}</label> : null}
        {isLoading && <Loading size="16" className="mb-2" />}
      </div>
      <input id={name} type="number" step={1 / Math.pow(10, decimalPlaces)} {...register(name, options)} {...{ options, name, mask, className: `input ${errors && "border-2 border-red-500"}`, ...props }} />
      <div className="flex justify-center bg-gray-100 rounded-md absolute right-0 bottom-2 h-10 w-10"></div>
      <MdOutlineArrowDropUp onClick={() => { setValue(name, Number((getValues(name) || 0) + 1).toFixed(decimalPlaces)) }} className="cursor-pointer text-primary absolute right-3 text-xl bottom-6 z-50" />
      <MdOutlineArrowDropDown onClick={() => { setValue(name, Number((getValues(name) || 0) - 1).toFixed(decimalPlaces)) }} className="cursor-pointer text-primary absolute right-3 text-xl bottom-3 z-50" />
    </div>
  )
}

export default AlertAmountInput