
import QuotationPanelContext from "context/QuotationPanelContext"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Modal from "react-modal"
import CloseButton from "shared/CloseButton"
import { toast } from 'react-toastify'
import SymbolSelectorAutocomplete from "shared/SymbolSelectorAutocomplete"
import { errorCodeHandler } from "helpers/errorCodeHandler"
import NotificationModal from 'shared/modal/NotificationModal'
import useWindowSize from "hooks/useWindowSize"
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from "const"

function ModalCreatePanelList({ isOpen = true, closeModal, listIdToEdit, createPanel }) {

  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH_MOBILE

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={{
      content:
      {
        maxWidth: isMobile ? width : 648,
        margin: "auto",
        marginRight: isMobile ? -40 : "auto",
        marginLeft: isMobile ? -40 : "auto",
        maxHeight: createPanel ? 250 : null
      }
    }}>
      <CloseButton onClick={closeModal} />
      <ContentCreatePanelList onSave={createPanel || closeModal} listIdToEdit={listIdToEdit} isMobile={isMobile} />
    </Modal>
  )
}

export function ContentCreatePanelList({ onSave, listIdToEdit, isMobile }) {

  const { register, getValues, reset } = useForm()
  const { createList, updateList, getListById, activeList } = useContext(QuotationPanelContext)
  const [symbols, setSymbols] = useState([])
  const [listToEdit, setListToEdit] = useState(null)
  const [isOpenNotification, setIsOpenNotification] = useState({ status: false })

  useEffect(() => {
    if (listIdToEdit) {
      const list = listIdToEdit === 'ACTIVE' ? activeList : getListById(listIdToEdit)
      if (listIdToEdit === 'ACTIVE' && !activeList) {
        toast.error('Crie uma lista antes de adicionar ativos!')
        onSave()
      }
      setListToEdit(list)
      setSymbols(list?.symbols)
      reset({ name: list?.name })
    }
  }, [listIdToEdit, activeList])

  const onSubmit = async (ev) => {
    ev.preventDefault()
    const name = getValues().name.trim().toUpperCase()
    if (!name || typeof name !== 'string') {
      toast.error('O campo nome é obrigatório!')
      return
    }

    if (name.replace(/\s/g, '').toLowerCase() === 'minhacarteira') return toast.error('O painel Minha carteira já existe!')

    try {
      if (!listToEdit) {
        await createList({ name, symbols })
        toast.success(`Painel ${name} criado com sucesso!`)
      } else {
        await updateList(listToEdit.id, { name, symbols })
        toast.success(`Painel ${name} atualizado com sucesso!`)
      }
      onSave()
    } catch (error) {
      setIsOpenNotification({ status: true, msg: errorCodeHandler(error) })
    }
  }

  return (
    <div className="relative flex-1 flex flex-col space-y-3 overflow-y-hidden h-full">
      <h2 className="font-bold text-xl text-primary uppercase">{listToEdit ? 'Editar painel' : 'Criar um novo painel'}</h2>
      <form className="space-y-2" onSubmit={onSubmit}>
        <label htmlFor="panelName" className="label font-semibold text-primary text-lg">Defina o nome do novo painel</label>
        <input autoFocus id="panelName" type="text" className="input" placeholder="Ex: Ativos em observação" {...register('name')} />
      </form>

      {listIdToEdit ? (
        <>
          <h3 className="font-bold text-primary text-lg">Ativos</h3>
          <SymbolSelectorAutocomplete onChange={setSymbols} symbolsList={symbols} />
        </>
      ) : <div className="flex-1"></div> }

      <button type="button" onClick={onSubmit} className="btn mt-8 btn-primary">
        {listToEdit ? 'Atualizar Painel' : 'Adicionar Ativos'}
      </button>
      <NotificationModal
        isOpen={isOpenNotification.status}
        closeModal={() => setIsOpenNotification({ status: false })}
        title={isOpenNotification?.msg}
        style={{
          content: {
            maxWidth: 380,
            maxHeight: isMobile ? 260 : 230,
            borderRadius: 10,
            margin: "auto",
            marginRight: isMobile ? -40 : "auto",
            marginLeft: isMobile ? -40 : "auto",
          }
        }}
        actionButtons='one'
        closeLabel='fechar'
      />
    </div>
  )
}


export default ModalCreatePanelList