import React, { createContext, useContext, useEffect, useState } from 'react'
import { LAYOUT_BREAKPOINT_WIDTH_TABLET, LAYOUT_BREAKPOINT_WIDTH_LG } from "const"
import { useLayoutEngine } from '../hooks/useLayoutEngine'

const LayoutEngineContext = createContext(null)

const calculateBreakpoints = () => {
  const margin = 15
  const widthDifference = window.innerWidth - (document.getElementById('workspaceGridLayout')?.clientWidth || 0) + margin
  
  return {
    mobile: 0,
    tablet: LAYOUT_BREAKPOINT_WIDTH_TABLET - widthDifference,
    desktop: LAYOUT_BREAKPOINT_WIDTH_LG - widthDifference
  }
}

export const LayoutEngineProvider = ({ workspace, children }) => {

  // Use the existing useLayoutEngine hook
  const [layout, setLayout] = useState(workspace.data || [])
  const layoutEngine = useLayoutEngine(workspace, layout, setLayout)
  const [responsiveGridLayout, setResponsiveGridLayout] = useState(null)
  const [breakpoints, setBreakpoints] = useState(calculateBreakpoints())
  const initialBreakpoints = window.innerWidth < breakpoints.tablet ? "mobile" : window.innerWidth < breakpoints.desktop ? "tablet" : "desktop"
  const [currentBreakpoint, setCurrentBreakpoint] = useState(initialBreakpoints)

  useEffect(() => {
    const handleResize = () => {
      setBreakpoints(calculateBreakpoints())
    }

    // Atualiza os breakpoints sempre que o tamanho da janela mudar
    window.addEventListener("resize", handleResize)
    handleResize()
    // Cleanup do evento ao desmontar o componente
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [responsiveGridLayout])


  return (
    <LayoutEngineContext.Provider value={{ layoutEngine, breakpoints, currentBreakpoint, setCurrentBreakpoint, layout, setLayout, setResponsiveGridLayout }}>
      {children}
    </LayoutEngineContext.Provider>
  )
}

export const useLayoutEngineContext = () => {
  const context = useContext(LayoutEngineContext)
  if (!context) {
    throw new Error('useLayoutEngineContext must be used within a LayoutEngineProvider')
  }
  return context
}



