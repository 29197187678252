import AlertsApi from "api/AlertsApi"
import { createContext, useEffect, useState } from "react"

export const AlertsContext = createContext({
  alertsQuoteList: [],
  alertsNewsList: [],
  newQuotationAlert: (param) => { },
  updateQuotationAlert: (param) => { },
  removeQuotationAlert: (param) => { },
  newNewsAlert: (param) => { },
  updateNewsAlert: (param) => { },
  removeNewsAlert: (param) => { }
})

export function AlertsContextProvider({ children }) {
  const [alertsQuoteList, setAlertsQuoteList] = useState([])
  const [alertsNewsList, setAlertsNewsList] = useState([])

  useEffect(() => {
    AlertsApi.fetchAlertsNews().then(res => setAlertsNewsList(res))
    AlertsApi.fetchAlertsQuote().then(res => setAlertsQuoteList(res))
  }, [])

  const newQuotationAlert = (param) => {
    AlertsApi.fetchNewAlertsQuote(param).then(() => {
      AlertsApi.fetchAlertsQuote().then(res => setAlertsQuoteList(res))
    })
  }

  const updateQuotationAlert = (param) => {
    AlertsApi.fetchUpdateAlertsQuote(param).then(() => {
      AlertsApi.fetchAlertsQuote().then(res => setAlertsQuoteList(res))
    })
  }

  const removeQuotationAlert = (param) => {
    return AlertsApi.fetchDeleteAlertsQuote(param).then(res => {
      const newQuoteList = [...alertsQuoteList]
      res.forEach(item => {
        const index = newQuoteList.findIndex(x => x.id === item.id)
        newQuoteList.splice(index, 1)
      })
      setAlertsQuoteList(newQuoteList)
    })
  }

  const newNewsAlert = (param) => {
    return AlertsApi.fetchNewAlertsNews(param).then(res => setAlertsNewsList([...alertsNewsList, res]))
  }

  const updateNewsAlert = (param) => {
    return AlertsApi.fetchUpdateAlertsNews(param).then(res => {
      const newNewsList = alertsNewsList.map(item => (item.id == res.id) ? res : item)
      setAlertsNewsList(newNewsList)
    })
  }

  const removeNewsAlert = (param) => {
    return AlertsApi.fetchDeleteAlertsNews(param)
      .then(res => {
        const newNewsList = [...alertsNewsList]
        res.forEach(item => {
          const index = newNewsList.findIndex(x => x.id === item.id)
          newNewsList.splice(index, 1)
        })
        setAlertsNewsList(newNewsList)
      })
  }

  return (
    <AlertsContext.Provider
      value={{
        alertsQuoteList,
        alertsNewsList,
        newQuotationAlert,
        updateQuotationAlert,
        removeQuotationAlert,
        newNewsAlert,
        updateNewsAlert,
        removeNewsAlert
      }}
    >
      {children}
    </AlertsContext.Provider>
  )
}

export default AlertsContext
