import { useEffect, useRef, useState } from "react"
import { MdSearch, MdClear, MdClose, MdSync } from "react-icons/md"
import CompaniesApi from "api/CompaniesApi"
import { toast } from "react-toastify"

function CompanyAutocomplete({
  setCompany,
  initialQuery,
  listClass = "absolute",
  customClass,
  customParentClass,
  autoFocus,
  extraOptions = [],
  placeholder,
  icon = <MdSearch />
}) {
  const [companiesList, setCompaniesList] = useState(null)
  const [companiesSelected, setCompaniesSelected] = useState(false)
  const [inputString, setInputString] = useState(initialQuery)
  const [activeListItem, setActiveListItem] = useState(-1)
  const inputRef = useRef(null)

  const options = [
    ...(companiesList?.map(payload => ({ type: 'company', payload })) || []),
    ...extraOptions
  ]

  useEffect(() => {
    (inputRef.current && autoFocus) && inputRef.current.focus()
  }, [autoFocus])

  useEffect(() => {
    if (!initialQuery) return
    CompaniesApi.search(initialQuery).then(r => {
      if (r.length)
        setCompany(r[0])
      setCompaniesList(null)
    })
  }, [initialQuery, setCompany, setCompaniesList])

  async function updateSearchValue(searchValue) {
    setInputString(searchValue)
    if (searchValue?.length < 1) {
      setCompaniesList(null)
      setActiveListItem(-1)
      return
    }
    searchValue = searchValue.toUpperCase()
    const response = await CompaniesApi.search(searchValue)
    setActiveListItem(-1)
    setCompaniesList(response || [])
  }

  function selectItem({ type, payload, callback }) {
    if (type === 'company') {
      setCompany(payload)
      setCompaniesList(null)
    }
    if (typeof callback === 'function')
      callback({ type, payload, inputString, companiesList })
    setInputString('')
    setCompaniesSelected(true)
    setActiveListItem(-1)
  }

  function clear() {
    setCompaniesList(null)
    setInputString('')
    setCompaniesSelected(false)
  }

  function handlePress(e) {
    // ENTER key
    // eslint-disable-next-line default-case
    switch (e.keyCode) {
      case 8: // Backspace
        setCompaniesSelected(false)
        break
      case 13: // ENTER
        e.preventDefault()
        if (options?.length) {
          const freeSearch = options.find(x => x.type === 'free_search')
          if (freeSearch !== -1 && activeListItem === -1)
            return selectItem(freeSearch)
          const index = (!activeListItem || activeListItem === -1) ? 0 : activeListItem
          selectItem(options[index])
        }

        break
      case 27: // Escape
        e.preventDefault()
        setCompaniesList(null)
        break
      case 38: // Arrow Up
        e.preventDefault()
        if (options?.length)
          setActiveListItem(val => val === 0 ? options?.length - 1 : val - 1)
        break
      case 40: // Arrow Down
        e.preventDefault()
        if (options?.length)
          setActiveListItem(val => val + 1 >= options.length ? 0 : val + 1)
        break
    }
  }

  return (
    <div className={`relative pb-1 ${customParentClass}`}>
      <div className="flex items-center">
        <input
          className={`rounded-lg bg-gray-100 p-2 px-4 text-sm w-full text-gray-600 ${customClass || ''} ${companiesSelected && 'font-bold text-dark-gray text-base'}`}
          onChange={e => updateSearchValue(e.target.value.toUpperCase())}
          onKeyDown={handlePress}
          id="searchInput"
          ref={inputRef}
          value={inputString}
          autoComplete="off"
          placeholder={placeholder} />
        {
          inputString ?
            <button className="absolute right-2 text-primary" type="button" onClick={(e) => { e.preventDefault(); clear() }}><MdClear /></button> :
            <button className="absolute right-2 text-gray-500" type="submit">
              {icon}
            </button>
        }
      </div>
      {inputString && (
        <div className={`rounded bg-white w-full border top-full mt-1 z-50 ${listClass}`}>
          {!companiesList?.length && <div className="p-2 text-gray-500 text-sm font-semibold opacity-50">Nenhuma empresa encontrada...</div>}
          {options.map((option, index) => (
            <div
              className={`p-2 hover:bg-gray-200 flex space-x-1 cursor-pointer items-center ${activeListItem === index && 'bg-blue-100'}`}
              key={'autocomplete_' + index}
              onClick={() => selectItem(option)}>
              {
                option.type === 'company' ? (
                  <div className="flex-1 flex-wrap items-baseline font-semibold">
                    <h3 className="text-base whitespace-nowrap mr-1">
                      <span className="mr-1">{option.payload.name}</span>
                      {/* <small className="opacity-50">{}</small> */}
                    </h3>
                    <small className="opacity-50 d-block">{option.payload.title} ({option.payload.symbols.join(', ')})</small>
                  </div>
                ) : option.Component({ companiesList, inputString })
              }

            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export function CompanySelectorAutocomplete({ companiesList = [], onChange = () => { }, maxCompaniesLength = Infinity }) {

  const [companies, setCompanies] = useState(companiesList)

  useEffect(() => { setCompanies(companiesList) }, [companiesList])

  if (!Array.isArray(companiesList)) return null

  const selectCompany = (company) => {
    if (maxCompaniesLength && companies.length === maxCompaniesLength) {
      toast.warning(`Máximo de ${maxCompaniesLength} ativos atingido!`)
      return
    }

    if (companies.some(x => x.title === company.title)) {
      toast.warning(`O ativo ${company.name} já está na incluso na lista!`)
      return
    }
    const newCompaniesList = [...companies, company]
    setCompanies(newCompaniesList)
    onChange(newCompaniesList)
  }

  const removeCompany = (company) => {
    if (companies.every(x => x.title !== company.title))
      return
    const newCompaniesList = companies.filter(s => s.title !== company.title)
    setCompanies(newCompaniesList)
    onChange(newCompaniesList)
  }

  return (
    <>
      <CompanyAutocomplete setCompany={selectCompany} />
      <div className="rounded border flex-1 p-2 h-44 overflow-auto mini-scrollbar">
        {companies.map((item, index) => (
          <div className="pl-3 pr-2 py-1 rounded-lg inline-flex items-center space-x-1 bg-gray-100 mr-2 mb-2" key={`company-selector-${index}`}>
            <span className="font-semibold text-gray-800" title={item.title}>{item.name}</span>
            {
              item.sync ? (
                <MdSync className="text-sm text-gray-400" title="Sincronizado da Minha Carteira" />
              ) : (
                <button type="button" className="text-sm text-gray-400 hover:text-gray-500" title={`Remover ${item.name}`} onClick={() => removeCompany(item)}><MdClose /></button>
              )
            }
          </div>
        ))}
        {!companies.length && (<div className="text-sm text-gray-400 m-2">Nenhum ativo selecionada...</div>)}
      </div>
      <div className="text-right text-sm font-semibold text-gray-400">
        Adicionando {companies.length} empresa(s)
      </div>
    </>
  )

}

export default CompanyAutocomplete