import { v4 as uuidv4, version as uuidVersion, validate as uuidValidate } from 'uuid';


export function generateWidgetId(type, genericId = null) {
  return `${type}:${genericId || ''}:${uuidv4()}`
}

export function validateWidgetId(id) {
  if (typeof id !== 'string')
    throw new Error('Invalid widget id! Id must be a string.')

  const [type,, uuid] = id.split(':')
  if (!type || !uuid)
    throw new Error('Invalid widget id! Id must be in the format "type:id".')

  if (!uuidValidate(uuid) || !uuidVersion(uuid) === 4)
    throw new Error('Invalid widget id! Invalid id.')

  return { type, id: uuid } 
}