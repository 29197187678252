import React, { useState } from "react"
import Modal from "react-modal"
import CloseButton from "shared/CloseButton"
import useWindowSize from "hooks/useWindowSize"
import { LAYOUT_BREAKPOINT_WIDTH } from "const"
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md"
import { BUCKET_URL } from 'const'

const videoOptions = {
  chromeAndroid: BUCKET_URL + '/assets/videos/chrome-android-cropped.mp4',
  chromeDesktop: BUCKET_URL + '/assets/videos/chrome-desktop.mp4',
  edgeDesktop: BUCKET_URL + '/assets/videos/edge-desktop.mp4',
  firefoxDesktop: BUCKET_URL + '/assets/videos/firefox-desktop.mp4'
}

function RequestNotificationPermission({ isOpen = true, closeModal }) {

  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH
  const [selectedVideo, setSelectedVideo] = useState("chromeAndroid")
  const [toggleSelect, setToggleSelect] = useState(null)

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={{
      content: {
        maxWidth: isMobile ? width : 648,
        margin: "auto",
        marginRight: isMobile ? -40 : "auto",
        marginLeft: isMobile ? -40 : "auto",
      }
    }} shouldCloseOnEsc={true}>
      <CloseButton onClick={closeModal} />
      <h2 className="font-bold text-xl text-primary uppercase mb-2">Reativar Notificações</h2>
      <div className="mini-scrollbar mt-5 space-y-5">
        <span>As notificações para o Valor One estão bloqueadas neste dispositivo. Siga os passos abaixo para habilitar as notificações no seu browser e receber alertas nesse dispositivo.</span>
        < div className="relative" >
          <select
            className="text-primary font-bold cursor-pointer input uppercase"
            value={selectedVideo}
            onClick={() => setToggleSelect(!toggleSelect)}
            onChange={(e) => { setSelectedVideo(e.target.value) }}
          >
            <option className="uppercase" value="chromeAndroid">Chrome - Android</option>
            <option className="uppercase" value="chromeDesktop">Chrome - Desktop</option>
            <option className="uppercase" value="edgeDesktop">Edge - Desktop</option>
            <option className="uppercase" value="firefoxDesktop">Firefox - Desktop</option>
          </select>
          {
            toggleSelect
              ? <MdOutlineArrowDropUp className="cursor-pointer absolute top-3 right-3 text-xl text-primary pointer-events-none" />
              : <MdOutlineArrowDropDown className="cursor-pointer absolute top-3 right-3 text-xl text-primary pointer-events-none" />
          }
        </div >
        <div className="video-container mt-4">
          <video key={selectedVideo} width="100%" autoPlay loop muted playsInline>
            <source src={videoOptions[selectedVideo]} type="video/mp4" />
            Seu navegador não suporta o elemento de vídeo.
          </video>
        </div>
        <div className="flex justify-center mx-auto">
          <button onClick={closeModal} className="btn btn-primary text-secondary">
            Fechar
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default RequestNotificationPermission



