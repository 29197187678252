import _widgetsSettings from "shared/widgets/_widgetsSettings"
import { generateWidgetId } from "helpers/generateWidgetId"

const _emptyLayout = [
  {
    type: "News",
    mobile: { w: 1, x: 0, y: 0 },
    tablet: { w: 1, x: 0, y: 0 },
    desktop: { w: 1, x: 0, y: 0 }
  },
  {
    type: "QuickQuote",
    mobile: { w: 1, x: 0, y: 1 },
    tablet: { w: 1, x: 1, y: 0 },
    desktop: { w: 1, x: 1, y: 0 }
  },
  {
    type: "Wallet",
    mobile: { w: 1, x: 0, y: 2},
    tablet: { w: 1, x: 0, y: 1 },
    desktop: { w: 1, x: 2, y: 0, h: 10  }
  },
  {
    type: "TradingSummary",
    mobile: { w: 1, x: 0, y: 3 },
    tablet: { w: 1, x: 1, y: 2 },
    desktop: { w: 1, x: 2, y: 7 }
  },
  {
    type: "QuotationPanel",
    mobile: { w: 1, x: 0, y: 4 },
    tablet: { w: 2, x: 0, y: 3 },
    desktop: { w: 2, x: 0, y: 13 }
  },
  {
    type: "MarketMap",
    mobile: { w: 1, x: 0, y: 5 },
    tablet: { w: 2, x: 0, y: 4 },
    desktop: { w: 1, x: 2, y: 19, h: 14 }
  },
  {
    type: "FixedIncomeCalculator",
    mobile: { w: 1, x: 0, y: 6 },
    tablet: { w: 1, x: 0, y: 5 },
    desktop: { w: 1, x: 0, y: 21 }
  },
  {
    type: "Simulator",
    mobile: { w: 1, x: 0, y: 7 },
    tablet: { w: 1, x: 1, y: 5 },
    desktop: { w: 1, x: 1, y: 21 }
  },
  {
    type: "Funds",
    mobile: { w: 1, x: 0, y: 8 },
    tablet: { w: 2, x: 0, y: 6 },
    desktop: { w: 3, x: 0, y: 32 }
  },
].map((item) => {
  const widget = _widgetsSettings.find((widget) => widget.type === item.type)
  if (!widget)
    throw new Error(`Widget "${item.type}" not found!`)
  
  const { desktop, tablet, mobile, params } = widget.defaults
  const id = generateWidgetId(item.type)
  return {
    id,
    desktop: { ...desktop, ...item.desktop, i: id },
    tablet: { ...tablet, ...item.tablet, i: id },
    mobile: { ...mobile, ...item.mobile, i: id },
    ...(params && { params }),
  }
})

export default _emptyLayout