import { useId, useState } from 'react'
import WidgetContextualMenu from 'shared/WidgetContextualMenu'
import { MdClose, MdOutlineLink, MdOutlineLinkOff } from 'react-icons/md'
import { SimpleTooltip } from 'shared/HelpTooltip'
import { Link } from 'react-router-dom'
import { useWalletUrl } from 'hooks/useWalletUrl'
import { WidgetTitle, WidgetLinkTitle } from "shared/WidgetTitle"
import { LoadingBox } from "shared/Loading"
import useTitle from "hooks/useTitle"
import DeniedPermissionWidget from "shared/DeniedPermissionWidget"
import { BUCKET_URL } from 'const'

const settingsRoute = '/configuracoes/minha-carteira'
const B3Logo = BUCKET_URL + '/assets/b3-logo.png'

export function WalletWidget({ id, w: widgetSize, ...props }) {
  const { urlWallet, isConnected } = useWalletUrl()

  return (
    <div className="bg-white min-h-full rounded flex flex-col">
      <div className="drag-handle pt-4 px-4">
        <WidgetContextualMenu removeItem={() => props.removeItem(id)} />
        <div className="flex justify-between pr-4">
          <WidgetLinkTitle route="/minha-carteira" title="Minha Carteira" />
          <ConnectionIcon isConnected={isConnected} style={{ marginTop: -3 }} />
        </div>
      </div>

      <DeniedPermissionWidget widgetSize={widgetSize} widgetType="Wallet">
        <ConnectionAlert />
        {
          urlWallet ? (
            <iframe src={urlWallet} className="w-full px-4 pb-4 flex-1 h-full" title="Minha Carteira" />
          ) : <LoadingBox />
        }
      </DeniedPermissionWidget>
    </div>

  )

}

export function WalletPage() {

  useTitle('Minha Carteira')
  const { urlWallet, isConnected } = useWalletUrl()

  return (
    <div className="bg-white h-full flex flex-col" >
      <div className="pt-4 px-4">
        <div className="flex justify-between pr-4">
          <WidgetTitle title="Minha Carteira" />
          <ConnectionIcon isConnected={isConnected} />
        </div>
      </div>
      <ConnectionAlert />
      {
        urlWallet ? (
          <iframe src={urlWallet} className="w-full px-4 pb-4 h-full" title="Minha Carteira" />
        ) : <LoadingBox />
      }
    </div>
  )
}

function ConnectionIcon({ isConnected, ...props }) {
  const id = useId()

  return (
    <>
      <span {...props} className="text-2xl rounded-full hover:bg-gray-200 h-8 w-8 flex items-center justify-center" id={'toggleIconConn_' + id}>
        <Link to={settingsRoute}>
          {isConnected ? <MdOutlineLink className="text-secondary" /> : <MdOutlineLinkOff className="text-primary" />}
        </Link>
      </span>
      <SimpleTooltip place="bottom" anchor={'#toggleIconConn_' + id} value={isConnected ? 'Conectado com a B3' : 'Contectar com a B3'} />
    </>
  )
}

function ConnectionAlert() {
  const { isConnected } = useWalletUrl()
  const [showAlert, setShowAlert] = useState(true)

  if (isConnected || !showAlert) return null

  return (
    <div className="border bg-gray-200 rounded p-6 flex space-x-2 relative mx-4 mb-4 ">
      <button className="absolute right-3 top-3 opacity-50 hover:opacity-100 z-50" onClick={() => setShowAlert(false)}>
        <MdClose />
      </button>
      <img src={B3Logo} alt="b3" className="h-10" />
      <div className="space-y-4">
        <p className="text-sm">Mantenha a sua carteira de ações, ETFs, fundos imobiliários e títulos do tesouro direto atualizada conectando sua conta com a B3. Você pode gerenciar essa conexão a qualquer momento nas Configurações do Valor One.</p>
        <div className="flex space-x-4 items-center">
          <Link to={settingsRoute}><ConnectionIcon className="text-5xl" /></Link>
          <Link to={settingsRoute}><button className="btn btn-primary text-secondary">{isConnected ? 'Desconectar' : 'Conectar'}</button></Link>
        </div>
      </div>
    </div>
  )
}

export default WalletPage