import { Link, useParams } from "react-router-dom"
import useTitle from "hooks/useTitle"
import InactivityPig from 'assets/imgs/inactivity-pig.svg'

export function SessionInactive() {
  const { status } = useParams()
  useTitle(status === 'inativa' ? 'Sessão encerrada por inatividade' : 'Sessões simultâneas')
 
  return (
    <section className="h-screen w-full flex max-md:flex-col max-md:gap-y-11 items-center justify-center md:space-x-24">
      <img className="max-xl:w-52" src={InactivityPig} alt="porco dormindo" />
      <div className="flex flex-col gap-y-[30px] max-w-[536px] px-[30px]">
        <div className="flex flex-col">
          {status === 'simultanea' && <span className="text-sm text-dark-gray uppercase font-semibold">sessões simultâneas</span>}
          <span className="text-2xl text-gray-70">{ status === 'simultanea' ? 'Detectamos que você se conectou ao Valor One em outro dispositivo/navegador.' : 'Sua sessão foi encerrada por inatividade.'}</span>
        </div>
        <Link to={'/workspace'} className={`${ status === 'simultanea' ? 'w-[147px] btn btn-primary text-secondary' : 'link underline'}`}>{status === 'simultanea' ? 'usar aqui' : 'Conecte-se novamente.'}</Link>
      </div>
    </ section>
  )
}