import { useContext, useState } from 'react'
import AuthContext from "context/AuthContext"
import useWindowSize from "hooks/useWindowSize"
import NotificationApi from 'api/NotificationApi'
import { PLANS, LAYOUT_BREAKPOINT_WIDTH, LAYOUT_BREAKPOINT_WIDTH_MD } from 'const'
import NotificationModal from 'shared/modal/NotificationModal'
import { CheckboxBasic } from 'shared/Checkbox'
import Loading from 'assets/loading.svg'
import useTitle from "hooks/useTitle"

export default function Plans() {
  useTitle('Planos')

  const { userInfo } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenSuccess, setIsOpenSuccess] = useState(false)
  const [planSelected, setPlanSelected] = useState(null)
  const [isChecked, setIsChecked] = useState(false)
  const [contentModal, setContentModal] = useState(null)

  const currentPlan = PLANS.findIndex((plan) => plan.code === userInfo.signature)

  const recommendedPlan = (plan) => {
    const recommend = PLANS.length === currentPlan + 1 ? '' : PLANS[currentPlan + 1 < 2 ? 2 : currentPlan + 1]
    if (recommend && (plan.code === recommend.code)) return true
    return false
  }
  const { width } = useWindowSize()

  const pageSize = (size) => {
    return size > LAYOUT_BREAKPOINT_WIDTH ? 'large' : size > LAYOUT_BREAKPOINT_WIDTH_MD ? 'medium' : 'small'
  }

  const activeCard = (i) => {
    return i > currentPlan ? true : false
  }

  const classContainer = {
    large: 'grid-cols-4',
    medium: 'grid-cols-2 place-content-between',
    small: 'grid-cols-1 justify-items-center'
  }

  const closeModal = () => {
    setIsOpen(false)
    setIsChecked(false)
    setIsOpenSuccess(false)
    setLoading(false)
    setContentModal(null)
  }
  const successModal = async () => {
    setLoading(true)
    const data = {
      newSignature: planSelected.code,
      price: planSelected.value
    }
    await NotificationApi
      .emailSales(data)
      .then(({ status }) => {
        if (status === 400) {
          return setContentModal({ icon: 'error', title: 'Solicitação de upgrade não finalizada. Tente novamente.' })
        }
        setContentModal({ icon: 'success', title: 'Solicitação de upgrade enviada com sucesso.' })
      })
      .catch((err) => {
        console.error(err)
        setContentModal({ icon: 'error', title: 'Solicitação de upgrade não finalizada. Tente novamente.' })
      })
      .finally(() => {
        setIsOpenSuccess(true)
        setIsOpen(false)
        setLoading(false)
      })
  }

  return (
    <div className={`flex flex-col ${pageSize(width) !== 'small' && 'm-[30px]'} bg-white rounded-t-xl`}>
      <h4 className="uppercase text-primary text-xl font-bold p-[30px]">planos</h4>
      <div className={`grid ${classContainer[pageSize(width)]} gap-[30px] mb-[30px] mx-[30px]`}>
        {PLANS.map((plan, i) => {
          return (
            <div key={plan.code} className={`relative max-w-[360px] min-w-[267px] overflow-hidden p-[30px] border-2 rounded-xl ${recommendedPlan(plan) ? 'border-primary' : 'border-[#DBDBDB]'}`}>
              <div className='flex flex-col items-center space-y-7'>
                <div className='flex flex-col space-y-3'>
                  <span className={`${activeCard(i) ? 'text-primary' : 'text-dark-gray'} text-4xl font-bold text-center`}>{plan.title}</span>
                  <span className='text-dark-gray'>{plan.description}</span>
                </div>
                {buttonType({ ...plan, ...plan.types.year }, 'primary', activeCard(i), userInfo.signature, setIsOpen, setPlanSelected)}
                {buttonType({ ...plan, ...plan.types.month }, 'link', activeCard(i), userInfo.signature, setIsOpen, setPlanSelected)}
                {plan.types.subscriber && <div className='flex flex-col items-center'>
                  <span className='text-dark-gray'>Assinante Valor Econômico:</span>
                  {buttonType({ ...plan, ...plan.types.subscriber }, 'secondary', activeCard(i), userInfo.signature, setIsOpen, setPlanSelected)}
                </div>}
                {plan.code !== 'VONEFRE' && <span>*no plano anual</span>}
              </div>
              {recommendedPlan(plan) && <div className='absolute -right-8 top-7 rotate-45 flex justify-center items-center bg-loss w-36 h-7'>
                <span className='text-white uppercase text-xs font-semibold'>recomendado</span>
              </div>}
              <div className='w-full my-[30px] border-b border-[#dbdbdb]'></div>
              {pageSize(width) === 'small'
                ? <details>
                  <summary className={`uppercase text-sm font-semibold ${activeCard(i) ? 'text-primary' : 'text-dark-gray'}`}>funcionalidades</summary>
                  {plan.functionalities.map((func) => {
                    return (
                      <div key={`${func.title}`} className='my-[30px]'>
                        <h1 className={`font-bold text-xl ${activeCard(i) ? func.title.includes('+') ? 'text-result' : 'text-primary' : 'text-dark-gray'}`}>{func.title}</h1>
                        {func.content && func.content.map((item) => {
                          return <div key={item} className='text-dark-gray'>{item}</div>
                        })}
                      </div>
                    )
                  })}
                </details>
                : <>
                  <div className={`uppercase text-sm font-semibold ${activeCard(i) ? 'text-primary' : 'text-dark-gray'}`}>funcionalidades</div>
                  {plan.functionalities.map((func) => {
                    return (
                      <div key={`${func.title}`} className='my-[30px]'>
                        <h1 className={`font-bold text-xl ${activeCard(i) ? func.title.includes('+') ? 'text-result' : 'text-primary' : 'text-dark-gray'}`}>{func.title}</h1>
                        {func.content && func.content.map((item) => {
                          return <div key={item} className='text-dark-gray'>{item}</div>
                        })}
                      </div>
                    )
                  })}
                </>
              }
            </div>
          )
        })}
      </div>
      <div className='text-xs/[16px] p-[30px] pt-0'>
        <span className='text-[#282828]'>
          1 - Valor ONE – Basic: ao optar pelo plano anual, será cobrado R$ 348,00 em até 12 parcelas mensais de R$ 29,00. Ao optar pelo plano mensal, será cobrado R$ 59,00 por mês.
          2 - Valor ONE – Standard: ao optar pelo plano anual, será cobrado R$ 1.008,00 em até 12 parcelas mensais de R$ 84,00. Ao optar pelo plano mensal, será cobrado R$ 129,00.
          3 - Valor ONE – Advanced: ao optar pelo plano anual, será cobrado R$ 1.788,00 em até 12 parcelas mensais de R$ 149,00. Ao optar pelo plano mensal, será cobrado R$ 249,00 por mês.
          4 - Valor ONE - Standard para assinantes Valor: ao optar pelo plano anual, será cobrado R$ 228,00 em até 12 parcelas mensais de R$ 19,00.
          5 - Valor ONE - Advanced para assinantes Valor: ao optar pelo plano anual, será cobrado R$ 588 em até 12 parcelas mensais de R$ 49,00.
          Para os planos mensais e anuais, serão aceitos pagamentos com cartão de crédito (American Express, MasterCard, Elo e Visa). Nos planos anuais, em qualquer modalidade de parcelamento, não há reembolso pelo período restante da vigência, caso
          o cancelamento ocorra em prazo inferior aos 12 meses contratados. <strong>Caso o assinante não se manifeste de forma contrária, para sua maior comodidade, a assinatura será renovada
            automaticamente ao término do período contratado, aos preços então vigentes. </strong> O Valor PRO e o Valor Internacional não fazem parte destes pacotes de assinaturas. Ofertas para
          novos assinantes/pessoa física. <strong>IMPORTANTE: Os itens 4 e 5 apresentam condições exclusivas para assinantes ativos do Valor Econômico Digital ou Impresso, válido apenas enquanto a assinatura estiver vigente. </strong>
        </span>
      </div>
      {
        planSelected &&
        <NotificationModal
          isOpen={isOpen}
          closeModal={closeModal}
          title={`Você confirma o upgrade para o pacote ${planSelected.title} por R$ ${planSelected.value}/mês no ${planSelected.label}?`}
          style={{ content: { maxWidth: 400, maxHeight: pageSize(width) === 'small' ? 450 : 350, borderRadius: 10, margin: "auto" } }}
        >
          <CheckboxBasic isChecked={isChecked} setIsChecked={setIsChecked}>
            <span className="ml-2 text-base flex items-center">Confirmo o upgrade e autorizo a alteração da cobrança.</span>
          </CheckboxBasic>
          <div className='w-full flex flex-wrap-reverse justify-center gap-5'>
            <button onClick={() => closeModal()} className='btn btn-secondary text-primary'>cancelar</button>
            <button onClick={() => successModal()} className={`flex gap-2 btn ${isChecked ? 'btn-primary text-secondary' : 'cursor-not-allowed text-grayDisabled bg-[#ededed]'}`} disabled={!isChecked}>confirmar</button>
          </div>
          {loading && <div className='absolute inset-0 flex justify-center items-center bg-white'>
            <img src={Loading} className='h-8 w-8 mr-2' alt="Carregando..." />
            <span>Aguarde...</span>
          </div>}
        </NotificationModal>
      }
      <NotificationModal
        isOpen={isOpenSuccess}
        closeModal={closeModal}
        icon={contentModal?.icon}
        title={contentModal?.title}
        style={{ content: { maxWidth: 400, maxHeight: contentModal?.icon === 'success' ? 320 : 250, borderRadius: 10, margin: "auto" } }}
      >
        {contentModal?.icon === 'success' && <span className="ml-2 text-base flex items-center">A efetivação do upgrade será realizada em até 2 dias úteis.</span>}
        <button onClick={() => closeModal()} className='btn btn-primary text-secondary'>ok</button>
      </NotificationModal>
    </div>
  )
}

function buttonType(plan, type = null, activeCard, activePlan, setIsOpen, setPlanSelected) {
  if (!plan && !type) return

  function clickAction() {
    setIsOpen(i => !i)
    setPlanSelected(plan)
  }

  const { code, link, value } = plan
  const labelContent = () => {
    if (code === 'VONEFRE' && type === 'primary') return <span>cadastro grátis</span>
    return (
      value && <div className='flex flex-wrap items-center justify-center'>
        {type !== 'link' && <span className='font-semibold text-base'>{`${type === 'primary' ? 'assine por' : type === 'secondary' && 'paga só +'}`}</span>}
        {type !== 'link' &&
          <span className='font-semibold text-xs lowercase'>
            <span className='font-bold text-xl uppercase ml-1'>{`R$ ${value}`}</span>
            /mês*
          </span>}
        {type === 'link' && <span className='text-center font-bold'>{`Ou R$ ${value} /mês no plano mensal`}</span>}
      </div>
    )
  }
  if (activePlan === 'VONEFRE' && activeCard) return <a
    className={`${type === 'link' ? 'link underline text-primary' : 'btn text-xl'} ${type === 'primary' ? 'btn-primary text-secondary' : type === 'secondary' && 'btn-secondary text-primary'} 'font-bold'`}
    href={link}
    target="_blank"
    rel="noopener noreferrer"
  >
    {labelContent()}
  </a>
  if (plan)
    return activeCard
      ? labelContent() && <button
        onClick={() => clickAction()}
        className={`${type === 'link' ? 'link underline text-primary' : 'btn text-xl'} ${type === 'primary' ? 'btn-primary text-secondary' : type === 'secondary' && 'btn-secondary text-primary'} 'font-bold'`}
      >
        {labelContent()}
      </button>
      : labelContent() && (type === 'link' ? <span className='cursor-not-allowed text-dark-gray text-center'>{labelContent()}</span> : <button className='uppercase py-3 px-7 rounded-xl text-center cursor-not-allowed font-semibold text-grayDisabled bg-[#ededed]' disabled={true}>
        {labelContent()}
      </button>)
}
