import moment from 'moment';

export function dateFormatter(type, firstDataRequest, gmt, delay, dateToParams) {
  const dateNow = delay ? moment().utc().subtract(delay, 'minute') : moment().utc()
  const dateLimitInMonth = moment('2000-01')
  const dateLimitInDay = moment('2000-01-01')
  const dateLimitInMinute = moment().subtract(3, 'months') // 90 days
  const dateBase = firstDataRequest ? dateNow.unix()*1000 : dateToParams
  let timestampToIso
  const openMarket = () => {
    return dateNow.hours() < 13 && dateNow.minutes() < 15 ? false : true
  }

  let dateTo,
      limiteDateFrom,
      dateFrom

  const periodTime = (type) => {
    const resolution = {
      '1': 'minute',
      '5': 'minute',
      '15': 'minute',
      '30': 'minute',
      '45': 'minute',
      '60': 'hour',
      '1D': 'day',
      '1W': 'week',
      '1M': 'month'
    }
    return resolution[type]
  }

  if (periodTime(type) === 'minute' || periodTime(type) === 'hour') {
    timestampToIso = dateBase > dateLimitInMinute.unix() ? moment(dateBase) : moment(dateLimitInMinute.unix()*1000)
    dateTo = openMarket() 
      ? firstDataRequest
        ? timestampToIso.utc().add(gmt, 'minute').format('YYYY-MM-DD HH:mm:ss')
        : timestampToIso.utc().subtract(1, 'day').format('YYYY-MM-DD 23:59:59') 
      : timestampToIso.utc().subtract(1, 'day').format('YYYY-MM-DD 23:59:59')
    limiteDateFrom = timestampToIso.unix() * 1000
  } else if (periodTime(type) === 'month') {
    timestampToIso = dateBase > dateLimitInMonth.unix() ? moment(dateBase) : moment(dateLimitInMonth.unix()*1000)
    dateTo = openMarket() ? timestampToIso.utc().format('YYYY-MM') : timestampToIso.utc().format('YYYY-MM')
    limiteDateFrom = timestampToIso.unix() * 1000
  } else {
    timestampToIso = dateBase > dateLimitInDay.unix() ? moment(dateBase) : moment(dateLimitInDay.unix()*1000)
    dateTo = openMarket() ? timestampToIso.utc().format('YYYY-MM-DD') : timestampToIso.utc().subtract(1, 'day').format('YYYY-MM-DD')
    limiteDateFrom = timestampToIso.unix() * 1000
  }

  switch (periodTime(type)) {
    case 'minute':
      dateFrom = dateToParams === dateLimitInMinute.unix() ?
        null :
        limiteDateFrom < dateLimitInMinute.unix() ?
        dateLimitInMinute.utc().format('YYYY-MM-DD 00:00:00') :
        timestampToIso.utc().subtract(3, 'day').format('YYYY-MM-DD 00:00:00')
      return { dateTo, dateFrom }
    case 'hour':
      dateFrom = dateToParams === dateLimitInMinute.unix() ?
        null :
        limiteDateFrom < dateLimitInMinute.unix() ?
        dateLimitInMinute.utc().format('YYYY-MM-DD 00:00:00') :
        timestampToIso.utc().subtract(3, 'day').format('YYYY-MM-DD 00:00:00')
      return { dateTo, dateFrom }
    case 'day':
      dateFrom = dateToParams === dateLimitInDay.unix() ?
        null :
        limiteDateFrom < dateLimitInDay.unix() ?
        dateLimitInDay.utc().format('YYYY-MM-DD') :
        timestampToIso.utc().subtract(1, 'year').format('YYYY-MM-DD')
      return { dateTo, dateFrom }
    case 'week':
      dateFrom = dateToParams === (dateLimitInDay.unix() * 1000) ?
        null :
        limiteDateFrom < (dateLimitInDay.unix() * 1000) ?
        dateLimitInDay.utc().format('YYYY-MM-DD') :
        timestampToIso.utc().subtract(4, 'year').format('YYYY-MM-DD')
      return { dateTo, dateFrom }
    case 'month':
      dateFrom = dateToParams === dateLimitInMonth.unix() ?
        null :
        limiteDateFrom < dateLimitInMonth.unix() ?
        dateLimitInMonth.utc().format('YYYY-MM') :
        timestampToIso.utc().subtract(6, 'year').format('YYYY-MM')
      return { dateTo, dateFrom }
    default:
      break;
  }
}

const colorUp = "#65A503"
const colorDown = "#FE260E"

export const bodyOverrides = {
  "mainSeriesProperties.candleStyle.upColor": colorUp,
  "mainSeriesProperties.candleStyle.downColor": colorDown,
  "mainSeriesProperties.candleStyle.borderUpColor": colorUp,
  "mainSeriesProperties.candleStyle.borderDownColor": colorDown,

  "mainSeriesProperties.barStyle.upColor": colorUp,
  "mainSeriesProperties.barStyle.downColor": colorDown,

  "mainSeriesProperties.hollowCandleStyle.upColor": colorUp,
  "mainSeriesProperties.hollowCandleStyle.downColor": colorDown,
  "mainSeriesProperties.hollowCandleStyle.borderUpColor": colorUp,
  "mainSeriesProperties.hollowCandleStyle.borderDownColor": colorDown,

  "mainSeriesProperties.columnStyle.upColor": colorUp,
  "mainSeriesProperties.columnStyle.downColor": colorDown
}
