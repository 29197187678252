import { useEffect, useState } from "react"
import { MdOutlineDiamond } from "react-icons/md"

function Tabs({ tabs, activeTabIndex, defaultTab = 0, onChange, parentClass, tabClass, tabSelectedClass, actionClick = true }) {

  const [tabIndex, setTabIndex] = useState(activeTabIndex)

  useEffect(() => {
    setTabIndex(typeof activeTabIndex === 'number' ? activeTabIndex : defaultTab)
  }, [activeTabIndex, defaultTab])

  const changeTab = (index, tab) => {
    if (tab.key && !tab.isActive) {
      setTabIndex(tabs.findIndex(item => item.isActive))
      return
    }
    setTabIndex(index)
    onChange({ tab: tabs[index], index })
  }

  return (
    <div className={`flex border-b-2 border-[#DBDBDB] text-grayDisabled text-sm font-semibold relative ${parentClass || ''}`}>
      {tabs.map((tab, index) => (
        <div
          key={`tab-${tab.name}`}
          className={`cursor-pointer relative top-[2px] uppercase flex-none ${tabIndex === index && "text-primary border-b-2 border-primary" } ${tabIndex === index && tabSelectedClass} ${tabClass || ''}`}
          onClick={() => actionClick && changeTab(index, tab)}
        >
          {tab.name}
          {(tab.key && !tab.isActive) && <MdOutlineDiamond className="ml-2 mb-1 text-terciary inline" />}
        </div>
      ))}
    </div>
  )
}

export default Tabs